import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { Page } from '@/models/page';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import Button from '@mui/material/Button';
import List from '@mui/material/List';

export const ReportClaimPage: Page = () => {
    const { getTranslated } = useTranslation();

    return (
        <SubPageTemplate
            title={getTranslated('insuranceReportClaimPage.title')}
        >
            <Typography>
                {getTranslated('insuranceReportClaimPage.content')}
            </Typography>

            <List>
                {translationTitles.map(
                    (item: StepsTGranslations, index: number) => (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: 'primary.main',
                                    }}
                                >
                                    {index + 1}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: getTranslated(item.title),
                                        }}
                                    />
                                }
                                secondary={
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: getTranslated(
                                                item.subtitle
                                            ),
                                        }}
                                    />
                                }
                            />
                        </ListItem>
                    )
                )}
            </List>

            <Typography
                sx={{
                    mb: 8,
                }}
                dangerouslySetInnerHTML={{
                    __html: getTranslated(
                        'insuranceReportClaimPage.bottom.label'
                    ),
                }}
            />

            <Typography
                sx={{
                    mr: 'auto',
                }}
            >
                {getTranslated('insuranceReportClaimPage.asterix')}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    mt: 3,
                }}
            >
                <Button
                    variant='contained'
                    href={'https://starjack.call-us.at/de'}
                >
                    {getTranslated('insuranceReportClaimPage.button.label')}
                </Button>
            </Box>
        </SubPageTemplate>
    );
};

ReportClaimPage.path = '/report-claim';

const translationTitles = [
    {
        title: 'insuranceReportClaimPage.list.step0.title',
        subtitle: 'insuranceReportClaimPage.list.step0.subtitle',
    },
    {
        title: 'insuranceReportClaimPage.list.step1.title',
        subtitle: 'insuranceReportClaimPage.list.step1.subtitle',
    },
    {
        title: 'insuranceReportClaimPage.list.step2.title',
        subtitle: 'insuranceReportClaimPage.list.step2.subtitle',
    },
    {
        title: 'insuranceReportClaimPage.list.step3.title',
        subtitle: 'insuranceReportClaimPage.list.step3.subtitle',
    },
    {
        title: 'insuranceReportClaimPage.list.step4.title',
        subtitle: 'insuranceReportClaimPage.list.step4.subtitle',
    },
] as const;

type StepsTGranslations = typeof translationTitles[number];
