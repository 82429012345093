import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import {
    Grid2,
    Link,
    LinkProps,
    Typography,
    TypographyProps,
} from '@mui/material';
import StarjackLogoWhite from '@/styles/images/Starjack-Logo_weiss.svg?react';
import { PaymentIcons } from '@/components/Footer/PaymentIcons';
import { SocialIcons } from '@/components/Footer/SocialIcons';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from '@/hooks/useTranslation';
import { styled } from '@mui/material/styles';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { usePageConfig } from '@/hooks/useConfig';

export const Footer: FunctionComponent = () => {
    const { origin } = window.location;
    const { getTranslated } = useTranslation();
    const { getInPageLinks } = useCustomRouter();
    const { routerConfig, getPagePath } = useCustomRouter();
    const { isPortal } = usePageConfig();

    return (
        <FlexContainer column fullWidth>
            <FooterMainContainer>
                <Grid2
                    sx={{
                        width: '100%',
                    }}
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    container
                >
                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 3,
                        }}
                    >
                        <Link href={'/'}>
                            <StarjackLogoWhite width='178' />
                        </Link>
                    </Grid2>
                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 3,
                        }}
                    >
                        <SectionTitle>Links</SectionTitle>
                        <ColumnLink
                            target='_blank'
                            href={`${origin}/lift-operators`}
                        >
                            {getTranslated('ticketOffers')}
                        </ColumnLink>
                        <ColumnLink
                            target='_blank'
                            href={`${origin}/new-keycard`}
                        >
                            {getTranslated('orderKeycards')}
                        </ColumnLink>
                        <ColumnLink target='_blank' href={`${origin}/vouchers`}>
                            {getTranslated('buyVouchers')}
                        </ColumnLink>
                        {isPortal && (
                            <ColumnLink
                                target='_blank'
                                href='https://starjackmerch.myspreadshop.de/'
                            >
                                {getTranslated('sidebar.merch.title')}
                            </ColumnLink>
                        )}

                        <ColumnLink
                            href={`${origin}${getPagePath(
                                routerConfig.InsuranceProductPage
                            )}`}
                            target='_blank'
                        >
                            {getTranslated(
                                'footer.linkColumn.insuracenProductPage'
                            )}
                        </ColumnLink>
                        <ColumnLink
                            href='https://starjack.com/faq'
                            target='_blank'
                            sx={{
                                mb: 6,
                            }}
                        >
                            FAQ&apos;s
                        </ColumnLink>
                        <ColumnLink
                            href='https://www.b2b-starjack.com/'
                            sx={{
                                mb: 6,
                            }}
                        >
                            {getTranslated('forB2b')}
                        </ColumnLink>
                    </Grid2>
                    <Grid2
                        size={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 3,
                        }}
                    >
                        <SectionTitle>
                            {getTranslated('customerService')}
                        </SectionTitle>
                        <ColumnText
                            sx={{
                                mb: 3,
                            }}
                        >
                            {getTranslated('openingHours')}
                        </ColumnText>
                        <ColumnLink target='_blank' href='tel:+43624673430999'>
                            <PhoneIcon sx={{ mr: 1 }} />
                            +43 6246 73430 999
                        </ColumnLink>
                        <ColumnLink
                            target='_blank'
                            href='mailto:support@starjack.com'
                        >
                            <MailOutlineIcon sx={{ mr: 1 }} />
                            support@starjack.com
                        </ColumnLink>
                    </Grid2>
                    <Grid2
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 3,
                        }}
                    >
                        <SectionTitle>
                            {getTranslated('footer.payment')}
                        </SectionTitle>
                        <PaymentIcons />
                        <br />

                        <SectionTitle>{getTranslated('followUs')}</SectionTitle>
                        <SocialIcons />
                    </Grid2>
                </Grid2>
            </FooterMainContainer>
            <FooterBottomContainer>
                <LegalLinksGridContainer
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Grid2
                        size={{
                            sm: 12,
                            md: 2.4,
                        }}
                        content='center'
                    >
                        <Typography
                            sx={{
                                color: 'primary.main',
                            }}
                        >
                            © SJACK GMBH - starjack
                        </Typography>
                    </Grid2>

                    <Grid2
                        size={{
                            sm: 12,
                            md: 2.4,
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        content='center'
                    >
                        <LegalNoticeLinks
                            target='_blank'
                            href={getInPageLinks('imprint')}
                        >
                            {getTranslated('footer.imprint')}
                        </LegalNoticeLinks>
                    </Grid2>
                    <Grid2
                        size={{
                            sm: 12,
                            md: 2.4,
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <LegalNoticeLinks
                            target='_blank'
                            href={getInPageLinks('gtc')}
                        >
                            {getTranslated('footer.gtc')}
                        </LegalNoticeLinks>
                    </Grid2>

                    <Grid2
                        size={{
                            sm: 12,
                            md: 2.4,
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <LegalNoticeLinks
                            target='_blank'
                            href={'/statusinformationen-one.pdf'}
                        >
                            {getTranslated('footer.statusinformation.label')}
                        </LegalNoticeLinks>
                    </Grid2>

                    <Grid2
                        size={{
                            sm: 12,
                            md: 2.4,
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <LegalNoticeLinks
                            target='_blank'
                            href={getInPageLinks('dataPrivacy')}
                        >
                            {getTranslated('footer.dataPrivacy')}
                        </LegalNoticeLinks>
                    </Grid2>
                </LegalLinksGridContainer>
            </FooterBottomContainer>
        </FlexContainer>
    );
};

const FooterMainContainer = styled(FlexContainer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(8, 10),
    width: '100%',
}));

const FooterBottomContainer = styled(FlexContainer)(({ theme }) => ({
    backgroundColor: '#38cfc8',
    flexWrap: 'wrap',
    padding: theme.spacing(4, 10),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap-reverse',
    },
}));

const SectionTitle: FunctionComponent<TypographyProps> = styled(Typography)(
    ({ theme }) => ({
        fontWeight: 600,
        fontSize: '1.2rem',
        color: '#38cfc8',
        marginBottom: theme.spacing(3),
    })
);

const ColumnLink: FunctionComponent<LinkProps> = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(2),
    color: theme.palette.white,
}));

const ColumnText = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(2),
    color: theme.palette.white,
}));

const LegalNoticeLinks: FunctionComponent<LinkProps> = styled(Link)(
    ({ theme }) => ({
        textDecoration: 'none',
        fontWeight: 600,
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: '1.2rem',
        '&:hover': {
            textDecoration: 'underline',
        },
    })
);

const LegalLinksGridContainer = styled(Grid2)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap-reverse',
    },
}));
